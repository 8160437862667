import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AuthGuard, Constants } from './_utility';
import { environment } from 'src/environments/environment';
import { RedirectLayoutComponent } from './_layout/redirect-layout/redirect-layout.component';
import { RedirectGuard } from './_utility/guards/redirect-guard';

const routes: Routes = [
  { path: '', redirectTo: '/login', pathMatch: 'full' },
  { path: Constants.Routing.DOCENTE.path, component: RedirectLayoutComponent, canActivate: [RedirectGuard], data: { externalUrl: environment.docente.redirect_url } },
  { path: Constants.Routing.STUDENTE.path, component: RedirectLayoutComponent, canActivate: [RedirectGuard], data: { externalUrl: environment.studente.redirect_url } },
  {
    path: Constants.Routing.REGISTRAZIONE_DOCENTE.path, loadChildren: () => import('./_layout/registrazione-docente/registrazione-docente.module').then(mod => mod.RegistrazioneDocenteModule)
  },
  {
    path: Constants.Routing.REGISTRAZIONE_STUDENTE.path, loadChildren: () => import('./_layout/registrazione-studente/registrazione-studente.module').then(mod => mod.RegistrazioneStudenteModule)
  },
  { path: Constants.Routing.LOGIN.path, loadChildren: () => import('./_layout/login/login.module').then(mod => mod.LoginModule) },
  { path: Constants.Routing.NOT_FOUND.path, loadChildren: () => import('./_layout/not-found/not-found.module').then(mod => mod.NotFoundModule) },
  { path: Constants.Routing.VERIFICA_EMAIL.path, loadChildren: () => import('./_layout/verifica-email/verifica-email.module').then(mod => mod.VerificaEmailModule), canActivate: [AuthGuard] },
  { path: Constants.Routing.CAMBIO_EMAIL.path, loadChildren: () => import('./_layout/verifica-email/verifica-email.module').then(mod => mod.VerificaEmailModule), canActivate: [AuthGuard] },
  // Cookie policy page
  // { path: Constants.Routing.COOKIE_POLICY.path, loadChildren: () => import('./_layout/cookie-policy/cookie-policy.module').then(mod => mod.CookiePolicyModule) },
  { path: '**', redirectTo: '/not_found' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { anchorScrolling: "enabled", scrollPositionRestoration: 'enabled' })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
